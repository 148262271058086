import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export interface Patent {
  code: string
  pdf: string
  status: string
  image: IGatsbyImageData | null
}

export const useWeterPatents = () => {
  const patents = useStaticQuery<{
    allPatent: {
      nodes: Array<{
        code: string
        pdf: string
        status: string
        image: {
          childImageSharp: { gatsbyImageData: IGatsbyImageData }
        }
      }>
    }
  }>(graphql`
    {
      allPatent(filter: { project: { eq: "wtr" } }, sort: { fields: code }) {
        nodes {
          code
          pdf
          status
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `).allPatent.nodes.map(({ image, code, ...rest }) => ({
    ...rest,
    code: code.toLowerCase(),
    image: image ? image.childImageSharp.gatsbyImageData : null,
  }))

  return patents
}
