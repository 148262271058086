import React, { useEffect } from 'react'
import { PageProps as PagePropsGatsby } from 'gatsby'
import { initLocalize } from 'react-localize-alias'
import ReactModal from 'react-modal'

// import './global.scss'
import './global_new.scss'

import { AppInit } from '@components/App/'
import { useLanguages, getTranslations } from '@graphql'
import { PageContext } from 'src/declaration'
import { setDayjsLocale } from '@utils'

export type PageProps = PagePropsGatsby<unknown, PageContext, unknown, unknown>

const Layout: React.FC<PageProps> = ({ children, ...props }) => {
  const languages = useLanguages()
  const translations = getTranslations()
  setDayjsLocale(props.pageContext.langCode)

  initLocalize({
    languages: languages.map(({ code, language }) => ({ code, name: language })),
    translations,
    options: {
      defaultLanguage: props.pageContext.langCode,
      onMissing: (data) => console.warn(data),
      onAlias: (alias) => {
        return alias.includes('landing.') ? alias : `${props.pageContext.project}.${alias}`
      },
    },
  })

  useEffect(() => {
    ReactModal.setAppElement('#___gatsby')
  }, [])

  return <AppInit {...props}>{children}</AppInit>
}

export default Layout
